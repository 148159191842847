export const ROLES = {
  ADMIN: 6,
  USER: 5,
}

export const ORDER_STATUS_NAMES = {
  rejected: 'rejected',
  pending: 'pending',
  paid: 'paid',
  preparing: 'preparing',
  ready: 'ready',
  done: 'done',
}

export const ORDER_STATUS = {
  [ORDER_STATUS_NAMES.rejected]: 'Rejeitado',
  [ORDER_STATUS_NAMES.pending]: 'Pendente',
  [ORDER_STATUS_NAMES.paid]: 'Pago',
  [ORDER_STATUS_NAMES.preparing]: 'Preparando',
  [ORDER_STATUS_NAMES.ready]: 'Pronto',
  [ORDER_STATUS_NAMES.done]: 'Entregue',
}