import React, { useState } from 'react';
import {
	Accordion,
	Table,
	Badge,
	Dropdown,
} from "react-bootstrap";
import swal from "sweetalert";

import './filtering.css';
import { useList } from './hooks/useList';
import { ProductsContext } from './hooks/context';
import { PAGE_INFO } from './config';
import ModalCategory from './modals/ModalCategory';
import { parseToBrl } from '../../utils';
import { status } from '../../utils/status';
import ModalItem from './modals/ModalItem';
import axiosInstance from '../../services/AxiosInstance';
import { NAMESPACE_PRODUCTS } from '../../enviroment/development';
import { ModalNewComplement } from './modals/ModalItem/modalNewComplement';
import { ModalProvider } from './modals/ModalItem/ModalNewComplementContext';
import { useStores } from '../../context/StoresProvider';

const type = 'products'
export const Products = () => {
	const { store } = useStores()
	const [showModalCategory, setShowModalCategory] = useState(false)
	const [showModalItem, setShowModalItem] = useState(false)
	const [itemDetailId, setItemDetailId] = useState()
	const [categorySelected, setCategorySelected] = useState()

	const { list, handleGetProductsByStore } = useList(store)

	const redirectPage = (value) => PAGE_INFO[type].LIST.EDIT(value)

	const handleToggleModalCategory = () => setShowModalCategory(!showModalCategory)
	const handleToggleModalItem = (id, category) => {
		setCategorySelected(category)
		setItemDetailId(id)
		setShowModalItem(!showModalItem)
	}

	const handleDelete = async (id) => {
		const response = await swal({
			icon: 'warning',
			title: 'Tem certeza?',
			text: 'Após a deleção, o produto será perdido.',
			buttons: [
				{
					text: 'Não, cliquei errado',
					value: false,
					closeModal: true,
					className: 'btn btn-success',
					visible: true
				},
				{
					text: 'Sim, quero deletar',
					value: 'deletar',
					className: 'btn btn-danger',
					closeModal: true,
					visible: true
				},
			]
		})

		if (response) {
			try {
				await axiosInstance.delete(`${NAMESPACE_PRODUCTS}/${id}`)
				swal({
					icon: 'success',
					title: 'Sucesso!',
					description: 'Produto deletado com sucesso!',
					timer: '5000'
				})
			} catch (error) {
				swal({
					icon: 'error',
					title: 'Oops!',
					description: 'Houve um erro ao deletar, contate o suporte para mais informações!',
					timer: '10000'
				})
			} finally {
				handleGetProductsByStore()
			}
		}
	}

	const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect x="0" y="0" width="24" height="24"></rect>
				<circle fill="#000000" cx="5" cy="12" r="2"></circle>
				<circle fill="#000000" cx="12" cy="12" r="2"></circle>
				<circle fill="#000000" cx="19" cy="12" r="2"></circle>
			</g>
		</svg>
	);

	return (
		<ModalProvider>
			<ProductsContext.Provider value={{ handleGetProductsByStore, redirectPage }}>
				<ModalCategory
					show={showModalCategory}
					closeModal={handleToggleModalCategory}
					successCallback={handleGetProductsByStore}
					store={store}
				/>
				<ModalItem
					show={showModalItem}
					closeModal={() => handleToggleModalItem()}
					category={categorySelected}
					id={itemDetailId}
					store={store}
					successCallback={handleGetProductsByStore}
				/>
				<ModalNewComplement /> 

				<div className="d-flex justify-content-between align-items-center mb-3">
					<h3>Cadápios</h3>
					<button onClick={handleToggleModalCategory} className="btn btn-primary mt-3 mt-sm-0">{PAGE_INFO[type].LIST.BUTTON}</button>
				</div>
				<div>
					<Accordion className="accordion accordion-secondary-solid" defaultActiveKey="0">
						{list?.map((data, i) => (
							<Accordion.Item key={data.id} eventKey={`${i}`}>
								<Accordion.Header className="accordion-header">
									{data.title}
								</Accordion.Header>
								<Accordion.Collapse eventKey={`${i}`} className="accordion__body">
									<div className="accordion-body">
										<Table responsive>
											<thead>
												<tr>
													<th className="width80">
														<strong>Capa</strong>
													</th>
													<th>
														<strong>Item</strong>
													</th>
													<th>
														<strong>Status</strong>
													</th>
													<th>
														<strong>Preço</strong>
													</th>
													<th width={50}></th>
												</tr>
											</thead>
											<tbody>
												{
													data?.products?.map(product => (
														<tr key={product.id}>
															<td>
																<img className='table-cover' src={product?.photo?.url} />
															</td>
															<td><strong>{product.title}</strong><br /> <span>{product.description}</span></td>
															<td>
																<Badge variant="success light">{status[product.active ? 1 : 0]}</Badge>
															</td>
															<td>{parseToBrl(product.price)}</td>
															<td>
																<Dropdown>
																	<Dropdown.Toggle
																		variant="success"
																		className="light sharp i-false"
																	>
																		{svg1}
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		<Dropdown.Item onClick={() => handleToggleModalItem(product.id, data.id)}>Editar</Dropdown.Item>
																		<Dropdown.Item onClick={() => handleDelete(product.id)}>Deletar</Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															</td>
														</tr>
													))
												}
											</tbody>
											<tfoot>
												<tr>
													<td colSpan={5}>
														<button onClick={() => handleToggleModalItem(null, data.id)} className="btn btn-outline-primary outline mt-3 mt-sm-0 d-flex align-items-center">
															<span className="btn-icon-start text-primary">
																<i className="fa fa-plus" />
															</span>

															Adicionar item</button>
													</td>
												</tr>
											</tfoot>
										</Table>

									</div>
								</Accordion.Collapse>
							</Accordion.Item >
						))}
					</Accordion>
				</div>
			</ProductsContext.Provider>
		</ModalProvider>
	)

}
export default Products;