import { useCallback, useEffect, useState } from "react";
import { useWebSocket } from "../../../hooks/useWebSocket";
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { useNewOrder } from "./hooks/useNewOrder";
import { ORDER_STATUS_NAMES } from "../../../utils/settings";
import { useNavigate } from "react-router-dom";

export const NewOrder = () => {
  const { joinRoom, addEventListener, removeEventListener } = useWebSocket();
  const navigate = useNavigate();
  const { auth: me } = useSelector((state) => state.auth);
  const { handleUpdateStatus } = useNewOrder();
  const [ordersQueue, setOrdersQueue] = useState([]);

  // Função para processar um pedido
  const processOrder = useCallback(async (order) => {
    const audio = new Audio('/songs/new-order.wav');
    audio.play();
    
    const result = await Swal.fire({
      title: 'Novo pedido!',
      text: `Novo pedido #${order.code}`,
      icon: 'info',
      confirmButtonText: 'Aceitar',
      cancelButtonText: 'Recusar',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });

    if(result.isConfirmed) {
      await handleUpdateStatus(order.id, ORDER_STATUS_NAMES.preparing);
      navigate(`/pedidos/?order=${order.id}`);
    } else {
      await handleUpdateStatus(order.id, ORDER_STATUS_NAMES.rejected);
    }

    // Remove o pedido processado da fila e chama o próximo, se houver
    setOrdersQueue(queue => {
      const updatedQueue = queue.slice(1);
      if (updatedQueue.length > 0) {
        processOrder(updatedQueue[0]);
      }
      return updatedQueue;
    });
  }, [handleUpdateStatus, navigate]);

  // Configura o WebSocket para adicionar novos pedidos na fila
  const handleMessages = useCallback((message) => {
    setOrdersQueue(queue => [...queue, message]);
  }, []);

  useEffect(() => {
    if (me.id) {
      joinRoom(me.id);
    }

    addEventListener('NEW_ORDER', handleMessages);

    return () => removeEventListener('NEW_ORDER', handleMessages);
  }, [me.id, joinRoom, addEventListener, removeEventListener, handleMessages]);

  // Verificar e iniciar o processamento para o primeiro pedido na fila se a fila estiver vazia inicialmente
  useEffect(() => {
    if (ordersQueue.length === 1) { // Se a fila tiver apenas 1 pedido, processa-o (evita múltiplas chamadas)
      processOrder(ordersQueue[0]);
    }
  }, [ordersQueue, processOrder]);

  return null;
};
