import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { NAMESPACE_CATEGORIES_PRODUCTS } from "../../../enviroment/development"
import axiosInstance from "../../../services/AxiosInstance"
import { parseResponse } from "../../../utils"
import Swal from "sweetalert2"

export const useList = (store) => {
  const [list, setList] = useState([])

  const handleGetProductsByStore = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Carregando...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      Swal.showLoading()
      const { data } = await axiosInstance.get('/products/categories', {
        params: {
          restaurant: store,
          populate: 'products'
        }
      })
  
      const categories = parseResponse(data.categories)
  
      setList(categories)
      Swal.close()
    } catch (error) {
      Swal.fire({
        title: 'Erro ao buscar produtos',
        text: 'Ocorreu um erro ao buscar os produtos, por favor tente novamente',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      
    }
  }, [store])

  useEffect(() => {
    if(store) {
      handleGetProductsByStore(store)
    }
  }, [store])
  
  return {
    list,
    handleGetProductsByStore
  }
}
