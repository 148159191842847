import React, { useState } from 'react';
import {
	Table,
	Dropdown,
} from "react-bootstrap";
import moment from 'moment';
import swal from "sweetalert";

import './filtering.css';
import { useList } from './hooks/useList';
import { PAGE_INFO } from './config';
import ModalNewRestaurante from './modals/ModalNewRestaurante';
import axiosInstance from '../../services/AxiosInstance';
import { ModalProvider } from './modals/ModalItem/ModalNewComplementContext';
import Swal from 'sweetalert2';
import { getOccupationText } from '../../utils/states';

export const Products = () => {
	const [showModalNewShopping, setShowNewShopping] = useState(false)
	const [itemDetailId, setItemDetailId] = useState()

	const { list, handleGetShoppings } = useList()

	const handleToggleModalNewShopping = () => setShowNewShopping(!showModalNewShopping)

	const handleToggleModalItem = (id) => {
		setItemDetailId(id)
		handleToggleModalNewShopping()
	}

	const handleDelete = async (id) => {
		const response = await swal({
			icon: 'warning',
			title: 'Tem certeza?',
			text: 'Após a deleção, os produtos desse restaurante serão perdidos.',
			buttons: [
				{
					text: 'Não, cliquei errado',
					value: false,
					closeModal: true,
					className: 'btn btn-success',
					visible: true
				},
				{
					text: 'Sim, quero deletar',
					value: 'deletar',
					className: 'btn btn-danger',
					closeModal: true,
					visible: true
				},
			]
		})

		if (response) {
			try {
				Swal.fire({
					title: 'Deletando...',
					allowEnterKey: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						Swal.showLoading()
					}
				})

				await axiosInstance.delete(`/restaurants/${id}`)
				swal({
					icon: 'success',
					title: 'Sucesso!',
					description: 'Restaurante deletado com sucesso!',
					timer: '5000'
				})
			} catch (error) {
				swal({
					icon: 'error',
					title: 'Oops!',
					description: 'Houve um erro ao deletar, contate o suporte para mais informações!',
					timer: '10000'
				})
			} finally {
				handleGetShoppings()
			}
		}
	}

	const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect x="0" y="0" width="24" height="24"></rect>
				<circle fill="#000000" cx="5" cy="12" r="2"></circle>
				<circle fill="#000000" cx="12" cy="12" r="2"></circle>
				<circle fill="#000000" cx="19" cy="12" r="2"></circle>
			</g>
		</svg>
	);

	return (
		<ModalProvider>
			<ModalNewRestaurante
				show={showModalNewShopping}
				closeModal={handleToggleModalNewShopping}
				successCallback={handleGetShoppings}
				id={itemDetailId}
			/>

			<div className="d-flex justify-content-between align-items-center mb-3">
				<h3>Usuários</h3>
				<button onClick={() => { setItemDetailId(null); handleToggleModalNewShopping()}} className="btn btn-primary mt-3 mt-sm-0">{PAGE_INFO.LIST.BUTTON}</button>
			</div>
			<div>
				<Table responsive>
					<thead>
						<tr>
							<th className="width80">
								<strong>Nome</strong>
							</th>
							<th className="width80">
								<strong>Restaurante</strong>
							</th>
							<th className="width80">
								<strong>Função</strong>
							</th>
							<th>
								<strong>Data de criação</strong>
							</th>
							<th width={50}></th>
						</tr>
					</thead>
					<tbody>
						{
							list?.map(item => (
								<tr key={item.id}>
									<td>{item?.employee?.name}</td>
									<td>{item?.restaurant?.title}</td>
									<td>{getOccupationText(item.role)}</td>
									<td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
									<td>
										<Dropdown>
											<Dropdown.Toggle
												variant="success"
												className="light sharp i-false"
											>
												{svg1}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => handleToggleModalItem(item.employee.id)}>Editar</Dropdown.Item>
												<Dropdown.Item onClick={() => handleDelete(item.employee.id)}>Deletar</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							))
						}
					</tbody>
				</Table>
			</div>
		</ModalProvider>
	)

}
export default Products;