import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import Swal from "sweetalert2";

import axiosInstance from "../../../../services/AxiosInstance";
import {
  removeMaskUsdToBrl,
  parseToBrlWithoutPrefix,
  parseResponse,
} from "../../../../utils";
import { ModalDetail } from "./modalDetail";
import { ModalComplement } from "./modalComplement";
import { defaultProduct } from "./defaultProduct";

function ModalItem({
  show,
  closeModal,
  id,
  category,
  store,
  type = "products",
  successCallback,
}) {
  const [product, setProduct] = useState({
    ...defaultProduct,
    productCategory: category,
    price: parseToBrlWithoutPrefix(defaultProduct.price),
  });
  const [categories, setCategories] = useState([]);
  const formikRef = useRef();

  useEffect(() => {
    if (id) {
      Swal.fire({
        title: "Carregando produto...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      axiosInstance
        .get(`/products/${id}`, {
          params: {
            type,
          },
        })
        .then((response) => {
          const productInfo = parseResponse(response.data.product);
          console.log("productInfo", productInfo);
          setProduct({
            ...productInfo,
            price: parseToBrlWithoutPrefix(productInfo.price || 0),
            productCategory: productInfo.productCategory.id,
            serves: String(productInfo.serves),
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          Swal.close();
        });
    }

    handleGetCategories();
  }, [id]);

  useEffect(() => {
    setProduct({
      ...product,
      productCategory: category,
    });
  }, [category]);

  //   fetch("http://localhost:3333/products/6/photo", {
  //   "headers": {
  //     "accept": "*/*",
  //     "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7,es;q=0.6",
  //     "authorization": "Basic dm5vd18yMDI0OmFaWEtKRGlx",
  //     "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryF81YRydJsfkvP8po",
  //     "sec-ch-ua": "\"Google Chrome\";v=\"123\", \"Not:A-Brand\";v=\"8\", \"Chromium\";v=\"123\"",
  //     "sec-ch-ua-mobile": "?0",
  //     "sec-ch-ua-platform": "\"Linux\"",
  //     "sec-fetch-dest": "empty",
  //     "sec-fetch-mode": "cors",
  //     "sec-fetch-site": "same-origin"
  //   },
  //   "referrer": "http://localhost:3333/docs",
  //   "referrerPolicy": "strict-origin-when-cross-origin",
  //   "body": "------WebKitFormBoundaryF81YRydJsfkvP8po\r\nContent-Disposition: form-data; name=\"photo\"; filename=\"subway.jpg\"\r\nContent-Type: image/jpeg\r\n\r\n\r\n------WebKitFormBoundaryF81YRydJsfkvP8po--\r\n",
  //   "method": "PATCH",
  //   "mode": "cors",
  //   "credentials": "include"
  // });
  const handleUploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("photo", file);
      Swal.fire({
        title: "Atualizando a imagem do produto...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const response = await axiosInstance.patch(
        `/products/${id}/photo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      Swal.fire("Imagem atualizada com sucesso!", "", "success");
      console.log(response);
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Oops, houve um erro ao atualizar a imagem do produto!",
        "Tente novamente, caso o problema persista, entre em contato com a equipe técnica.",
        "error"
      );
    }
  };

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];

    handleUploadImage(file);

    const base64 = await convertToBase64(file);
    setFieldValue("photo.url", base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      Swal.fire({
        title: `${id ? "Atualizando" : "Criando"} o produto...`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (id) {
        axiosInstance
          .put(`/products/${id}`, {
            ...values,
            price: removeMaskUsdToBrl(values.price),
            restaurant: store,
          })
          .then(() => {
            setSubmitting(false);
            Swal.fire("Produto atualizado com sucesso!", "", "success");
            successCallback();
          })
          .catch((error) => {
            console.error(error);
            Swal.fire(
              "Oops, houve um erro ao atualizar o produto!",
              "Tente novamente, caso o problema persista, entre em contato com a equipe técnica.",
              "error"
            );

            setSubmitting(false);
          });
      } else {
        let produtId = "";
        axiosInstance
          .post("/products", {
            ...values,
            price: removeMaskUsdToBrl(values.price),
            restaurant: store,
          })
          .then((response) => {
            setSubmitting(false);
            produtId = response.data.id;
            Swal.fire("Produto criado com sucesso!", "", "success");
            successCallback();
          })
          .catch((error) => {
            setSubmitting(false);
            Swal.fire(
              "Oops, houve um erro ao criar o produto!",
              "Tente novamente, caso o problema persista, entre em contato com a equipe técnica.",
              "error"
            );
          })
          .finally(() => {
            handleClose();
          });
      }
    },
    [id, store, successCallback]
  );

  const handleClose = () => {
    closeModal();
    setProduct(defaultProduct);
  };

  const handleGetCategories = useCallback(async () => {
    if (store) {
      const { data } = await axiosInstance.get("/products/categories", {
        params: {
          restaurant: store,
          populate: "products",
        },
      });

      const categories = parseResponse(data.categories);

      setCategories(categories);
    }
  }, [store]);

  return (
    <Modal className="fade" show={show} size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title>{!id ? "Novo item" : "Editar produto"}</Modal.Title>
        <Button variant="" className="btn-close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <Tab.Container defaultActiveKey={"detail"}>
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="detail">
                <i className={`la la-align-left me-2`} />
                Detalhes
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item as="li">
              <Nav.Link eventKey="complements">
                <i className={`la la-plus me-2`} />
                Complementos
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="disponibility">
                <i className={`la la-info-circle me-2`} />
                Disponibilidade
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey={"detail"}>
              <ModalDetail
                formikRef={formikRef}
                product={product}
                handleSubmit={handleSubmit}
                categories={categories}
                handleFileChange={handleFileChange}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={"complements"}>
              <ModalComplement
                formikRef={formikRef}
                product={product}
                handleSubmit={handleSubmit}
                categories={categories}
                handleFileChange={handleFileChange}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="danger light" type="button">
          Fechar
        </Button>
        <Button
          onClick={() => formikRef.current.handleSubmit()}
          type="button"
          variant="primary"
        >
          {!id ? "Criar produto" : "Salvar alterações"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalItem;
