import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from "../../../services/AxiosInstance";
import { NAMESPACE_PRODUCTS } from "../../../enviroment/development";
import Swal from 'sweetalert2'
import { PAGE_INFO } from "../config";

const productSchema = Yup.object().shape({
  title: Yup.string().required("Oops, parece que você esqueceu do título..."),
  description: Yup.string().required("Oops, parece que você esqueceu da descrição..."),
  serves: Yup.string(),
  price: Yup.string().required("Oops, parece que você esqueceu do preço...")
});

const ProductForm = ({ type }) => {
  const { id } = useParams();
  const [product, setProduct] = useState({ title: "", description: "", serves: "", price: "", cover: "" });

  const navigate = useNavigate()
  useEffect(() => {
    if (id) {
      axiosInstance.get(`${NAMESPACE_PRODUCTS}/${id}`, {
        params: {
          type
        }
      })
        .then((response) => {
          setProduct(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    setFieldValue("cover", base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    Swal.fire({
      title: `${id ? 'Atualizando' : 'Criando'} o produto...`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()          
      }
    })

    if (id) {
      axiosInstance.put(`${NAMESPACE_PRODUCTS}/${id}`, values, {
        params: {
          type
        }
      })
        .then(() => {
          setSubmitting(false)
          Swal.fire('Produto atualizado com sucesso!', '', 'success');
        })
        .catch(error => {
          console.error(error);
          Swal.fire('Oops, houve um erro ao atualizar o produto!', 'Tente novamente, caso o problema persista, entre em contato com a equipe técnica.', 'error');

          setSubmitting(false);
        });
    } else {
      let produtId = ''
      axiosInstance.post(NAMESPACE_PRODUCTS, values, {
        params: {
          type
        }
      })
        .then((response) => {
          setSubmitting(false)
          produtId = response.data.id
          Swal.fire('Produto criado com sucesso!', '', 'success');
        })
        .catch(error => {
          setSubmitting(false);
          Swal.fire('Oops, houve um erro ao criar o produto!', 'Tente novamente, caso o problema persista, entre em contato com a equipe técnica.', 'error');
        }).finally(() => {
          navigate(`/produtos/${produtId}`)
        });
    }
  }
  
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{id ? PAGE_INFO[type].EDIT.TITLE : PAGE_INFO[type].FORM.TITLE}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={product}
                  validationSchema={productSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>       
                      <div className="row">               
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Nome do produto*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                            {errors.title && touched.title && <div className="invalid-feedback animated fadeInUp">{errors.title}</div>}
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-label">Descrição*</label>
                            <textarea
                              rows={6}
                              type="text"
                              className="form-control"
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                            />
                            {errors.description && touched.description && <div className="invalid-feedback animated fadeInUp">{errors.description}</div>}
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-label">Serve quantas pessoas? (opcional)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="serves"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.serves}
                            />
                            {errors.serves && touched.serves && <div className="invalid-feedback animated fadeInUp">{errors.serves}</div>}
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-label">Preço*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.price}
                            />
                            {errors.price && touched.price && <div className="invalid-feedback animated fadeInUp">{errors.price}</div>}
                          </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 text-center">
                          <img style={{ width: '260px'}} className="mb-3 rounded" src={values.cover}/>
                          <div className="form-group mb-3">
                            <label className="text-label">Capa do produto*</label>
                            <input
                              type="file"
                              className="form-control"
                              name="cover"
                              accept="image/*"
                              onChange={(event) =>
                                handleFileChange(event, setFieldValue)
                              }
                            />
                            {errors.cover && touched.cover && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.cover}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    

                      <button
                        type="submit"
                        className="btn me-2 btn-primary"
                        disabled={isSubmitting}
                      >
                        {id ? PAGE_INFO[type].EDIT.BUTTON : PAGE_INFO[type].FORM.BUTTON}
                      </button>
                      <button type="button" className="btn btn-light" onClick={() => navigate(id ? PAGE_INFO[type].EDIT.REDIRECT : PAGE_INFO[type].FORM.REDIRECT)}>Cancelar</button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductForm;
