export const BASE_URL = process.env.REACT_APP_API_URL_MIDDLEWARE
export const NAMESPACE_USER_DETAIL = '/users/me'
export const NAMESPACE_STORES_DETAIL = (slug) => `/stores/${slug}`
export const NAMESPACE_CATEGORIES = '/categories'
export const NAMESPACE_CATEGORIES_PRODUCTS = '/categories/products'
export const NAMESPACE_PRODUCTS = '/products'
export const NAMESPACE_COMPLEMENTS = '/choices'
export const NAMESPACE_ADDITIONALS_PRODUCTS_LIST = '/products/additional-product/list'
export const NAMESPACE_ADDITIONALS_PRODUCTS_CREATE = '/products/additional-product/create'
export const NAMESPACE_ADDITIONALS_PRODUCTS_UPDATE = '/products/additional-product/update'

export const NAMESPACE_PRODUCT_DETAIL = (slug, id) => `/stores/${slug}/products/detail/${id}`
export const FIREBASE_API_KEY = 'AIzaSyC47Mw8V9VA790UfqJF1MtdYreX9zaGTW0'

export const ACCESS_TOKEN_KEY = 'userDetails'