export const MenuList = [
    {
        title: 'Menu principal',
        classsChange: 'menu-title',
        isAdmin: true,
        isUser: true
    },
    //Dashboard
    // {
    //     title: 'Dashboard',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: "bi bi-grid",
    //     content: [

    //         {
    //             title: 'Dashboard Light',
    //             to: 'dashboard',					
    //         },
    //         {
    //             title: 'Food Order',
    //             to: 'food-order',

    //         },
    //         {
    //             title: 'Favorite Menu',
    //             to: 'favorite-menu',
    //         },
    //         {
    //             title: 'Message',
    //             to: 'message',
    //         },
    //         {
    //             title: 'Order History',
    //             to: 'order-history',

    //         },
    //         {
    //             title: 'Notification',
    //             to: 'notification',

    //         },
    //         {
    //             title: 'Bill',
    //             to: 'bill',

    //         },
    //         {
    //             title: 'Setting',
    //             to: 'setting',

    //         },

    //     ],
    // },
    //Restaurant
    {
        title: 'Shoppings',
        noCollapse: true,
        to: 'shoppings',
        iconStyle: "bi bi-shop",
        isAdmin: true
    },
    {
        title: 'Restaurantes',
        noCollapse: true,
        to: 'restaurantes',
        iconStyle: "bi bi-shop-window",
        isAdmin: true
    },
    {
        title: 'Usuários',
        noCollapse: true,
        to: 'usuarios',
        iconStyle: "bi bi-people",
        isAdmin: true
    },
    {
        title: 'Meu restaurante',
        noCollapse: true,
        to: 'meu-restaurante',
        iconStyle: "bi bi-shop-window",
        isUser: true
    },
    {
        title: 'Cardápios',
        noCollapse: true,
        to: 'cardapios',
        iconStyle: "bi bi-card-list",
        isUser: true
    },
    {
        title: 'Pedidos',
        noCollapse: true,
        to: 'pedidos',
        iconStyle: "bi bi-cart",
        isUser: true
    },
    //Drivers
    // {
    //     title: 'Drivers',
    //     classsChange: 'mm-collapse',
    //     iconStyle: "bi bi-bicycle",
    //     content: [
    //         {
    //             title:'Dashboard',
    //             to: 'deliver-main'
    //         },
    //         {
    //             title:'Orders',
    //             to: 'deliver-order'
    //         },
    //         {
    //             title:'Feedback',
    //             to: 'feedback'
    //         },
    //     ],
    // },
    // {
    //     title: 'Other',
    //     classsChange: 'menu-title'
    // },
    //Apps
    // {
    //     title: 'Apps',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: "bi bi-info-circle",
    //     content: [
    //         {
    //             title: 'Profile',
    //             to: 'app-profile'
    //         },
    //         {
    //             title: 'Post Details',
    //             to: 'post-details'
    //         },
    //         {
    //             title: 'Email',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Compose',
    //                     to: 'email-compose',
    //                 },
    //                 {
    //                     title: 'Index',
    //                     to: 'email-inbox',
    //                 },
    //                 {
    //                     title: 'Read',
    //                     to: 'email-read',
    //                 }
    //             ],
    //         },
    //         {
    //             title:'Calendar',
    //             to: 'app-calender'
    //         },
    //         {
    //             title: 'Shop',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Product Grid',
    //                     to: 'ecom-product-grid',
    //                 },
    //                 {
    //                     title: 'Product List',
    //                     to: 'ecom-product-list',
    //                 },
    //                 {
    //                     title: 'Product Details',
    //                     to: 'ecom-product-detail',
    //                 },
    //                 {
    //                     title: 'Order',
    //                     to: 'ecom-product-order',
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     to: 'ecom-checkout',
    //                 },
    //                 {
    //                     title: 'Invoice',
    //                     to: 'ecom-invoice',
    //                 },
    //                 {
    //                     title: 'Customers',
    //                     to: 'ecom-customers',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    //Charts    
]

export const menus = {
    admin: MenuList.filter(item => item.isAdmin),
    user: MenuList.filter(item => item.isUser),
    all: MenuList
}