export const PAGE_INFO = {
  LIST: {
    TITLE: 'Shoppings',
    BUTTON: 'Adicionar shopping',
    FORM: '/shoppings/novo',
    EDIT: (id) => `/shoppings/${id}`,
  },
  FORM: {
    TITLE: 'Novo shopping',
    BUTTON: 'Cadastrar shopping',
    REDIRECT: '/shoppings'
  },
  EDIT: {
    TITLE: 'Editar shopping',
    BUTTON: 'Atualizar shopping',
    REDIRECT: '/shoppings'
  },
}
