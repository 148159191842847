import { useCallback, useEffect, useState } from "react"
import axiosInstance from "../../../services/AxiosInstance"
import { parseResponse } from "../../../utils"
import Swal from "sweetalert2"

export const useList = () => {
  const [list, setList] = useState([])
  const [pagination, setPagination] = useState({})
  const [page, setPage] = useState(1)

  const handleGetShoppings = useCallback(async () => {
    Swal.fire({
      title: 'Carregando...',
      allowEnterKey: false,
      allowEscapeKey: false,
      showConfirmButton: false,

      didOpen: () => {
        Swal.showLoading()
      }
    })
    const { data: response } = await axiosInstance.get('/restaurants', {
      params: {
        page
      }
    })
    setList(parseResponse(response?.restaurants))
    setPagination(response?.pagination)

    Swal.close()
  }, [page])

  useEffect(() => {
    handleGetShoppings()
  }, [page])
  
  return {
    list,
    pagination,
    setPage,
    handleGetShoppings
  }
}
