import * as Yup from "yup";

export const complementSchema = Yup.object().shape({
  title: Yup.string().required("Oops, parece que você esqueceu do título..."),
  min: Yup.number(),
  max: Yup.number(),
  type: Yup.string().oneOf(["one", "many"]).required("Oops, parece que você esqueceu do tipo..."),
  required: Yup.boolean().required("Oops, parece que você esqueceu da informação se é obrigatório ou não..."),
  items: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("Oops, parece que você esqueceu do ID do item..."),
      title: Yup.string().required("Oops, parece que você esqueceu do título do item..."),
      status: Yup.string(),
      description: Yup.string(),
      pdvCode: Yup.string(),
      price: Yup.string(),
      cover: Yup.string(),
    })
  )
});
