import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import StatisticChart from "./StatisticChart";

import {
  DoubleRight,
  CircleRight,
  CircleExclamation,
  EarningIcon,
  PointIcon,
  TimerIcon,
  MinGrapg,
  AvgGraph,
} from "./SvgIcons";
import { useDetail } from "./hooks/useList";
import { parseToBrl } from "../../utils"

const EarningBlog = [{ number: "11.240", IconClass: "success" }];

const DeliverMain = () => {
  const [dropValue, setDropValue] = useState("This Month");
  const { detail } = useDetail();

  const performance = [
    {
      iconblog: TimerIcon,
      title: "Vendas",
      percent: parseToBrl(detail?.totalSold)
    },
    {
      iconblog: MinGrapg,
      title: "Crescimento",
      percent: detail?.growthFromSevenDaysAgo || 0,
    },
    {
      iconblog: AvgGraph,
      title: "Valor médio",
      percent: parseToBrl(detail?.averageTicket),
    },
  ];
  return (
    <>
      <div className="row">
        <div className="col-xl-5">
          <div className="row">
            <div className="col-xl-4 col-xxl-6 col-sm-4 sp15">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center">
                    {CircleRight}
                    <h4 className="mb-0">{detail?.totalOrders}</h4>
                    <p className="mb-0">Total de pedidos</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-6 col-sm-4 sp15">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center">
                    {DoubleRight}
                    <h4 className="mb-0">{detail?.totalFinished}</h4>
                    <p className="mb-0">Total finalizado</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-6 col-sm-4 sp15">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center">
                    {CircleExclamation}
                    <h4 className="mb-0">{detail?.totalRejected}</h4>
                    <p className="mb-0">Total cancelado</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card h-auto">
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                {EarningBlog.map((item, index) => (
                  <div
                    className="d-flex align-items-center mb-3 mb-sm-0 content-space"
                    key={index}
                  >
                    <div
                      className={`icon-bx d-inline-block text-center me-3 bg-${item.IconClass}`}
                    >
                      {EarningIcon}
                    </div>
                    <div>
                      <p className="mb-0">Vendas de hoje</p>
                      <h4>{parseToBrl(detail?.todaySells)}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="row">
            {performance.map((data, i) => (
              <div className="col-xl-4 col-sm-4" key={i}>
                <div className="card">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="icon-bx style-2 d-inline-block text-center mb-3">
                        {data.iconblog}
                      </div>
                      <h4>{data.percent}</h4>
                      <p className="mb-0">{data.title}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliverMain;
