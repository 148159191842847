import { useEffect, useState } from "react"
import axiosInstance from "../../../services/AxiosInstance"
import { parseResponse } from "../../../utils"
import Swal from "sweetalert2"

export const useDetail = () => {
  const [detail, setDetail] = useState({})
  console.log("detail???", detail)
  const handleGet = async () => {
    Swal.fire({
      title: 'Carregando...',
      allowEnterKey: false,
      allowEscapeKey: false,
      showConfirmButton: false,

      didOpen: () => {
        Swal.showLoading()
      }
    })
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const { data: response } = await axiosInstance.get('/dashboard', {
      params: {
        restaurant: userDetails?.employeeOf[0].restaurant
      }
  
    })
    setDetail(parseResponse(response?.dashboard))

    Swal.close()
  }

  useEffect(() => {
    handleGet()
  }, [])
  
  return {
    detail,
    handleGet
  }
}
