import axios from 'axios';
import { ACCESS_TOKEN_KEY, BASE_URL } from '../enviroment/development';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const details = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (details) {
        const token = JSON.parse(details);
        config.headers['authorization'] = `Bearer ${token.jwt}`;

        if (token.stores) {
            config.headers['store'] = token.stores[0];
        }
    }
    return config;
});

export default axiosInstance;
