import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Field } from "formik";
import { productSchema } from './productSchema';
import { useModal } from './ModalNewComplementContext';

export function ModalComplement({ formikRef, product, handleSubmit, categories, handleFileChange }) {
  const [hasComplement, setHasComplement] = useState('1');
  const { openModal } = useModal();
  console.log('product', product)

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={product}
        validationSchema={productSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fragment>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <h3 className="text-label">Complementos</h3>
                      <p>Seu item tem complementos?</p>
                    </div>

                    <div className="form-group mb-3">
                      <div className="col-sm-9">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="noComplement"
                            value="1"
                            onChange={() => setHasComplement('1')}
                            checked={hasComplement === '1'}
                          />
                          <label for="noComplement" className="form-check-label">
                            Não, este item não tem complementos
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="hasComplement"
                            value="2"
                            onChange={() => setHasComplement('2')}
                            checked={hasComplement === '2'}
                          />
                          <label for="hasComplement" className="form-check-label">
                            Sim, este item tem complementos
                          </label>
                        </div>
                      </div>
                    </div>

                    {hasComplement === '2' && (
                      <div className="form-group">
                        <button type='button' className='btn btn-primary' onClick={() => openModal(product.id)}>Criar grupo de complemento</button>
                        &nbsp;
                        &nbsp;
                        <button type='button' className='btn btn-secondary'>Complemento existente</button>
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
