import { useCallback, useEffect, useState } from "react"
import axiosInstance from "../../../services/AxiosInstance"
import { parseResponse } from "../../../utils"
import Swal from "sweetalert2"

export const useList = () => {
  const [list, setList] = useState([])
  const [pagination, setPagination] = useState({})
  const [page, setPage] = useState(1)

  const handleGetList = useCallback(async () => {
    Swal.fire({
      title: 'Carregando...',
      allowEnterKey: false,
      allowEscapeKey: false,
      showConfirmButton: false,

      didOpen: () => {
        Swal.showLoading()
      }
    })
    const { data: response } = await axiosInstance.get('/orders/restaurants/list', {
      params: {
        page
      }
    })
    setList(parseResponse(response?.orders))
    setPagination(response?.pagination)

    Swal.close()
  }, [page])

  useEffect(() => {
    handleGetList()
  }, [page])
  
  return {
    list,
    pagination,
    setPage,
    handleGetList
  }
}
