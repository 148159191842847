import React, { Fragment } from "react";
import { Formik, Field } from "formik";
import NumberFormat from "react-number-format";
import { productSchema } from "./productSchema";

export function ModalDetail({
  formikRef,
  product,
  handleSubmit,
  categories,
  handleFileChange,
}) {
  return (
    <Formik
      innerRef={formikRef}
      initialValues={product}
      validationSchema={productSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Fragment>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Nome do produto*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {errors.title && touched.title && (
                      <div className="invalid-feedback animated fadeInUp">
                        {errors.title}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-3">
                    <label className="text-label">Descrição*</label>
                    <textarea
                      style={{ minHeight: 70 }}
                      type="text"
                      className="form-control"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    {errors.description && touched.description && (
                      <div className="invalid-feedback animated fadeInUp">
                        {errors.description}
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label className="text-label">Categoria</label>
                        <select
                          type="text"
                          className="form-control"
                          name="productCategory"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.productCategory}
                        >
                          {categories.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        {errors.productCategory && touched.productCategory && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.productCategory}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label className="text-label">
                          Serve quantas pessoas?
                        </label>
                        <select
                          type="text"
                          className="form-control"
                          name="serves"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.serves}
                        >
                          <option value="1">1 pessoa</option>
                          <option value="2">2 pessoas</option>
                          <option value="3">3 pessoas</option>
                          <option value="4">4 pessoas</option>
                          <option value="5">5 ou mais pessoas</option>
                        </select>
                        {errors.serves && touched.serves && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.serves}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="text-label">Preço*</label>
                    <Field name="price">
                      {({ field }) => (
                        <NumberFormat
                          {...field}
                          decimalSeparator=","
                          className="form-control"
                          thousandSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const { price } = values;
                            handleChange({
                              target: { name: "price", value: price },
                            });
                          }}
                        />
                      )}
                    </Field>
                    {errors.price && touched.price && (
                      <div className="invalid-feedback animated fadeInUp">
                        {errors.price}
                      </div>
                    )}
                  </div>
                </div>

                <>
                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <label className="text-label">Status</label>
                      <select
                        type="text"
                        className="form-control"
                        name="active"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.active}
                      >
                        <option value={true}>Ativo</option>
                        <option value={false}>Inativo</option>
                      </select>
                      {errors.status && touched.status && (
                        <div className="invalid-feedback animated fadeInUp">
                          {errors.status}
                        </div>
                      )}
                    </div>

                    {product?.id && (
                      <>
                        <div className="text-center">
                          <img
                            style={{ width: "161px" }}
                            className="mb-3 rounded text-center"
                            src={
                              values?.photo?.url ||
                              `/images/placeholder-cover.png`
                            }
                          />
                        </div>
                        <div className="form-group mb-3 text-center">
                          <label className="text-label">Capa do produto*</label>
                          <input
                            type="file"
                            className="form-control"
                            name="cover"
                            accept="image/*"
                            onChange={(event) =>
                              handleFileChange(event, setFieldValue)
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            </Fragment>
          </form>
        );
      }}
    </Formik>
  );
}
