import moment from "moment";
export const COLUMNS = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Titulo",
    accessor: "title",
  },
  {
    Header: "Rua",
    accessor: "address.street",
  },
  {
    Header: "Cidade",
    accessor: "address.city",
  },
  {
    Header: "Estado",
    accessor: "address.state",
  },
  {
    Header: "Data de criação",
    accessor: (item) => moment(item.createdAt).format("DD/MM/YYYY"),
  },
  {
    Header: "Ações",
    accessor: "action",
  },
];
