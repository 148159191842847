import React, { Fragment, useCallback } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import axiosInstance from '../../../../services/AxiosInstance';

const categorySchema = Yup.object().shape({
  title: Yup.string().required("Oops, parece que você esqueceu de inserir o nome da categoria...")
});


function ModalCategory({ show, store, closeModal, successCallback }) {

  const onSubmitForm = useCallback((values, props) => {
    Swal.fire({
      title: 'Criando a categoria...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    axiosInstance.post('/products/categories', {
      ...values,
    }, {
      params: {
        restaurant: store
      }
    })
      .then(() => {
        props.setSubmitting(false);
        Swal.fire('Categoria criada com sucesso!', '', 'success').then(() => closeModal());
        successCallback()
      })
      .catch(error => {
        props.setSubmitting(false);
        Swal.fire('Oops, houve um erro ao criar a categoria!', 'Tente novamente, caso o problema persista, entre em contato com a equipe técnica.', 'error');
      }).finally(() => {
        props.resetForm()
      });
  }, [store, successCallback])

  return (

    <Modal className="fade" show={show}>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={categorySchema}
        onSubmit={onSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title>Nova categoria</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={closeModal}
              >

              </Button>
            </Modal.Header>
            <Modal.Body>
              <Fragment>
                <div className="row">
                  <div className="col-lg-12">

                    <div className="form-group mb-3">
                      <label className="text-label">Nome da Categoria*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {errors.title && touched.title && (
                        <div className="invalid-feedback animated fadeInUp">
                          {errors.title}
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </Fragment>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeModal}
                variant="danger light"
                type='button'
              >
                Fechar
              </Button>
              <Button type='submit' variant="primary">Criar categoria</Button>
            </Modal.Footer>
          </form>

        )}
      </Formik>
    </Modal>
  )
}

export default ModalCategory;
