import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState();

  const openModal = (id) => {
    setIsModalOpen(true);
    setId(id)
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setId()
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, id, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
