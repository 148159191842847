import { createContext, useContext, useState } from "react";

export const StoresContext = createContext({});

export const StoresProvider = ({ children }) => {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);

  return (
    <StoresContext.Provider
      value={{
        store,
        setStore,
        loading,
        setLoading,
      }}
    >
      {children}
    </StoresContext.Provider>
  );
}

export const useStores = () => {
  const context = useContext(StoresContext);

  if (!context) {
    throw new Error('useUser must be used within an StoresProvider');
  }

  return context;
}