import React, { Fragment, useCallback, useEffect, useRef, useState, forwardRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import { parseResponse } from "../../../../utils";
import { ORDER_STATUS, ORDER_STATUS_NAMES } from "../../../../utils/settings";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';

const DEFAULT_VALUES = {
  status: "",
};

const OrderPreview = forwardRef(({ orderDetails }, ref) => {
  return (
    <div ref={ref} className="order-preview mb-3" style={{ background: "#fdeded" }}>
      <pre>
        <code style={{ margin: '0 auto' }}>
          ---------------------------------{'\n'}
          {moment(orderDetails.createdAt).format('DD/MM/YYYY HH:mm:ss')}{'\n'}
          --- CÓDIGO RETIRADA {orderDetails.code} ---{'\n'}
          APLICATIVO: E-PEDIDO{'\n'}
          {'\n'}
          QTD PRODUTO{'\n'}
          ---{'\n'}
          {(orderDetails.items || []).map(item => (
            <Fragment key={item.id}>
              {item.quantity} {item.product.title.toUpperCase()} (000{item.product.id}){'\n'}
              --- SEM CEBOLA{'\n'}
            </Fragment>
          ))}
          --------------------------------
        </code>
      </pre>
    </div>
  )
});

function ModalNew({ id, show, closeModal, successCallback }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint();

  useEffect(() => {
    setInitialValues(DEFAULT_VALUES);
    if (id) {
      Swal.fire({
        title: "Carregando...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      axiosInstance.get(`/orders/${id}`).then(({ data }) => {
        const parsedResponse = parseResponse(data.order);
        console.log(parsedResponse);
        setInitialValues({
          ...parsedResponse,
          ...parsedResponse.address,
        });

        Swal.close();
      });
    }
  }, [id]);

  const onSubmitForm = useCallback(
    (values, props) => {
      Swal.fire({
        title: `Atualizando o status do pedido...`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      axiosInstance.post("/pagarme/orders/status", {
        data: {
          status: values.status,
          code: id,
        }
      })
        .then(async () => {
          props.setSubmitting(false);
          await Swal.fire(
            `Pedido atualizado com sucesso!`,
            "",
            "success"
          ).then(() => closeModal());
          successCallback();
        })
        .catch((error) => {
          props.setSubmitting(false);
          Swal.fire(
            "Oops, houve um erro ao atualizar o pedido!",
            "Tente novamente, caso o problema persista, entre em contato com a equipe técnica.",
            "error"
          );
        })
        .finally(() => {
          props.resetForm();
        });
    },
    [id, successCallback, closeModal]
  );


  return (
    <Modal className="fade" show={show}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>Pedido #{initialValues?.code}</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={closeModal}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <Fragment>
                  <div className="row">
                    {
                      values?.items?.length > 0 && (
                        <div className="col-12">
                          <OrderPreview orderDetails={values} ref={componentRef} />
                        </div>
                      )
                    }
                    <div className="form-group mb-3">
                      <label className="text-label">Status*</label>
                      <select
                        className="form-control"
                        name="status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.status}
                      >
                        {
                          Object.keys(ORDER_STATUS).map((status) => status !== ORDER_STATUS_NAMES.paid && (
                            <option key={status} value={status}>
                              {ORDER_STATUS[status]}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </Fragment>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={closeModal}
                  variant="danger light"
                  type="button"
                >
                  Fechar
                </Button>
                <Button type="button" variant="info" onClick={() => handlePrint(null, () => componentRef.current)}>
                  Imprimir
                </Button>


                <Button type="submit" variant="primary">
                  Mudar status
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ModalNew;
