import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import axiosInstance from '../../../../services/AxiosInstance';
import { getTimezone, parseResponse } from '../../../../utils';

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  password: Yup.string().optional(),
  restaurant: Yup.string().required('Campo obrigatório'),
  occupation: Yup.string().required('Campo obrigatório')
});

const DEFAULT_VALUES = {
  name: "",
  email: "",
  password: "",
  restaurant: ""
}

function ModalNewRestaurante({ id, show, closeModal, successCallback }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES)
  const [listOfRestaurants, setListOfRestaurants] = useState([]);
  useEffect(() => {
    setInitialValues(DEFAULT_VALUES)
    if (id) {
      Swal.fire({
        title: 'Carregando...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });

      axiosInstance.get(`/users/employee/${id}`)
        .then(({ data }) => {
          const parsedResponse = parseResponse(data.user);

          console.log("parsedResponse", parsedResponse)

          setInitialValues({
            ...parsedResponse,
            restaurant: parsedResponse.employeeInfo.restaurant.id,
            occupation: parsedResponse.employeeInfo.role
          });

          Swal.close();
        }).finally(() => {
          Swal.close();
        });
    }
  }, [id]);

  const handleGetListOfRestaurants = useCallback(() => {
    axiosInstance.get('/restaurants', {
      params: {
        tz: getTimezone()
      }
    })
      .then(({ data }) => {
        const parsedResponse = parseResponse(data.restaurants);
        setListOfRestaurants(parsedResponse);
      });
  }, []);

  useEffect(() => {
    handleGetListOfRestaurants();
  }, [handleGetListOfRestaurants]);

  const onSubmitForm = useCallback((values, props) => {
    Swal.fire({
      title: `${!id ? 'Criando' : 'Atualizando'} o usuário...`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const method = id ? 'put' : 'post';

    axiosInstance[method]('/users/employee', {
      ...values,
      id,
      active: true,
    })
      .then(() => {
        props.setSubmitting(false);
        Swal.fire(`Usuário ${id ? 'atualizado' : 'criado'} com sucesso!`, '', 'success').then(() => closeModal());
        successCallback()
      })
      .catch(error => {
        props.setSubmitting(false);
        Swal.fire('Oops, houve um erro ao ' + id ? 'atualizar' : 'criar' + ' o usuário!', 'Tente novamente, caso o problema persista, entre em contato com a equipe técnica.', 'error');
      }).finally(() => {
        props.resetForm()
      });
  }, [id, successCallback])

  return (

    <Modal className="fade" show={show}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmitForm}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => {
          console.log("values", values)
          console.log("errors", errors)
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>{id ? 'Atualizar' : 'Novo'} usuário</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={closeModal}
                >

                </Button>
              </Modal.Header>
              <Modal.Body>
                <Fragment>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Nome do usuario*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Senha*</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        {errors.password && touched.password && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Cargo*</label>
                        <select
                          className="form-control"
                          name="occupation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.occupation}
                        >
                          <option value="">Selecione...</option>
                          <option value="1">Gerente</option>
                          <option value="2">Atendente</option>
                        </select>
                        {errors.occupation && touched.occupation && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.occupation}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Restaurante</label>
                        <select
                          className="form-control"
                          name="restaurant"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.restaurant}
                        >
                          <option value="">Selecione...</option>
                          {listOfRestaurants.map(restaurant => (
                            <option key={restaurant.id} value={restaurant.id}>{restaurant.title}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </Fragment>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={closeModal}
                  variant="danger light"
                  type='button'
                >
                  Fechar
                </Button>
                <Button type='submit' variant="primary">{!id ? 'Criar' : 'Atualizar'} usuário</Button>
              </Modal.Footer>
            </form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalNewRestaurante;
