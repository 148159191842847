import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { COLUMNS } from "./columns";
import { Button, Dropdown } from "react-bootstrap";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const Render = ({ list, handleToggleModalItem }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = list

  const tableInstance = useTable({ columns, data }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <table {...getTableProps()} className="table dataTable display">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="ml-1">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          className="fa fa-arrow-down ms-2 fs-14"
                          style={{ opacity: "0.7" }}
                        />
                      ) : (
                        <i
                          className="fa fa-arrow-up ms-2 fs-14"
                          style={{ opacity: "0.7" }}
                        />
                      )
                    ) : (
                      <i
                        className="fa fa-sort ms-2 fs-14"
                        style={{ opacity: "0.3" }}
                      />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === "action") {
                    return (
                      <>
                        <Button
                          variant="link"
                          onClick={() =>
                            handleToggleModalItem(cell.row.original.id)
                          }>
                            Visualizar
                        </Button>
                      </>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/* This is only for footer if u require */}
      </table>
    </>
  );
};
const PedidosTable = ({ list, handleToggleModalItem, handleDelete }) => {
  if (!list.length) return;

  return (
    <Render
      list={list}
      handleToggleModalItem={handleToggleModalItem}
      handleDelete={handleDelete}
    />
  );
};

export default PedidosTable;
