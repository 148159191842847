import React from "react";
import { Nav, Pagination } from "react-bootstrap";

const PaginationComponent = ({ page, pageCount, setPage }) => {
  const active = page;

  const pag = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      className={`mt-4 ${gutter ? "pagination-gutter" : ""} ${variant && `pagination-${variant}`
        } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      {
        page > 1 && (
          <li className="page-item page-indicator">
            <span className="page-link" onClick={() => setPage(page - 1)}>
              <i className="la la-angle-left" />
            </span>
          </li>
        )
      }
      {
        [...Array(pageCount).keys()].slice(0, 3).map((page) => (
          <Pagination.Item key={page + 1} active={(page + 1) === active} onClick={() => setPage(page + 1)}>
            {page + 1}
          </Pagination.Item>
        ))
      }

      {
        pageCount > 5 && (
          <Pagination.Item disabled>...</Pagination.Item>
        )
      }

      {
        pageCount > 3 ? (
          <>
            {
              [...Array(pageCount).keys()].slice(pageCount - 2, pageCount).map((page) => (
                <Pagination.Item key={page + 1} active={(page + 1) === active} onClick={() => setPage(page + 1)}>
                  {page + 1}
                </Pagination.Item>
              ))
            }
          </>
        ) : (<></>)
      }


      <li className="page-item page-indicator">
        <span className="page-link" onClick={() => setPage(page + 1)}>
          <i className="la la-angle-right" />
        </span>
      </li>
    </Pagination>
  );

  return pageCount > 1 ? <Nav>{pag("", false, "", true, false)}</Nav> : <></>
};

export default PaginationComponent;
