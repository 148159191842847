export const parseToBrl = (price) => parseFloat(price).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

export const parseToBrlWithoutPrefix = (price) => parseFloat(price).toLocaleString('pt-BR', { 
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const removeMaskUsdToBrl = (usdValue) => {
  // Remover caracteres não numéricos, como o símbolo do dólar e as vírgulas
  const numericValue = usdValue.replace(/[^0-9.,]/g, '');

  // Substituir a vírgula por ponto (formato de ponto flutuante)
  const floatValue = numericValue.replace(',', '.');

  // Converter a string para número e retornar
  return String(parseFloat(floatValue));
}


export const parseResponse = (originalJson) => {
  if (!originalJson) {
    return originalJson;
  }
  const processObject = (obj) => {

    if (obj.hasOwnProperty('data') && typeof obj.data === 'object') {
      // Move the contents of 'data' to the top level
      Object.assign(obj, obj.data);
      delete obj.data;
    }

    if (obj.hasOwnProperty('attributes')) {
      // Move attributes to the top level
      Object.assign(obj, obj.attributes);
      delete obj.attributes;
    }

    // Recursively process for subobjects and arrays
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((element) => {
            if (element && typeof element === 'object') {
              processObject(element);
            }
          });
        } else {
          processObject(obj[key]);
        }
      }
    }
  };

  // Check if the original JSON is an array and process each item
  if (Array.isArray(originalJson)) {
    originalJson.forEach(item => processObject(item));
  } else {
    // Process a single object
    processObject(originalJson);
  }

  return originalJson;
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};