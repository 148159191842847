import React, { Fragment, useState } from 'react';
import { Button, Modal, } from 'react-bootstrap';
import { Formik, Field, FieldArray, useField } from "formik";
import NumberFormat from 'react-number-format';
import { complementSchema } from './complementSchema';
// import ReactDragListView from 'react-drag-listview/lib/index.js';
import { useModal } from './ModalNewComplementContext';

import { v4 } from 'uuid'
import Swal from 'sweetalert2';
import axiosInstance from '../../../../services/AxiosInstance';
import { NAMESPACE_COMPLEMENTS } from '../../../../enviroment/development';
import { removeMaskUsdToBrl } from '../../../../utils';

const initialValues = {
  title: "",
  description: "",
  min: "",
  max: "",
  type: "one",
  required: false,
  items: []
};

export function ModalNewComplement() {
  const { isModalOpen, closeModal, id } = useModal();

  if (!isModalOpen) return null;


  const handleSubmitForm = (values, { setSubmitting }) => {
    Swal.fire({
      title: `${id ? 'Atualizando' : 'Criando'} o grupo de complementos...`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    const payload = {
      productId: id,
      ...values,
      ...(values.min && { min: String(values.min) }),
      ...(values.max && { max: String(values.max) }),
      items: values?.items?.map(item => {
        return {
          ...item,
          price: removeMaskUsdToBrl(item.price)
        }
      })
    }

    // if (id) {
    //   axiosInstance.put(`${NAMESPACE_COMPLEMENTS}/${id}`, payload)
    //     .then(() => {
    //       setSubmitting(false)
    //       Swal.fire('Grupo de complementos atualizado com sucesso!', '', 'success');
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       Swal.fire('Oops, houve um erro ao atualizar o grupo de complementos!', 'Tente novamente, caso o problema persista, entre em contato com a equipe técnica.', 'error');

    //       setSubmitting(false);
    //     });
    // } else {
      let produtId = ''
      console.log("payload", payload)
      axiosInstance.post(NAMESPACE_COMPLEMENTS, payload)
        .then((response) => {
          setSubmitting(false)
          produtId = response.data.id
          Swal.fire('Grupo de complementos criado com sucesso!', '', 'success');
        })
        .catch(error => {
          console.log("error", error)
          setSubmitting(false);
          Swal.fire('Oops, houve um erro ao criar o grupo de complementos!', 'Tente novamente, caso o problema persista, entre em contato com a equipe técnica.', 'error');
        }).finally(() => closeModal())
    // }
  }

  const handleFileChange = async (event, name, setFieldValue) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    setFieldValue(name, base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={complementSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values, errors, handleChange, handleBlur, handleSubmit, setFieldValue
      }) => {
        return (
          <Modal className="fade" show={isModalOpen} size="lg" backdrop='static'>
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>
                  {!id ? 'Novo grupo de complementos' : 'Editar grupo de complementos'}
                </Modal.Title>
                <Button
                  variant=''
                  className="btn-close"
                  onClick={closeModal}
                />
              </Modal.Header>
              <Modal.Body>
                <Fragment>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Nome do grupo de complementos *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title} />
                        {errors.title && <div className="invalid-feedback animated fadeInUp block">{errors.title}</div>}
                      </div>

                      <div className="form-group mb-3">
                        <strong className="text-label">Obrigatoriedade *</strong>
                        <p>Indique se essa categoria é necessária para pedir o prato</p>
                        <div className="col-sm-9">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name='required'
                              id="noComplement"
                              value={false}
                              defaultChecked={!values.required}
                            />
                            <label for="noComplement" className="form-check-label">
                              Opcional - O cliente pode ou não selecionar os itens
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name='required'
                              id="hasComplement"
                              value={true}
                              defaultChecked={values.required}
                            />
                            <label for="hasComplement" className="form-check-label">
                              Obrigatório - O cliente deve escolher pelo menos um item
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <strong className="text-label">Tipo de seleção *</strong>
                        <p>É grupo de seleção única ou múltipla?</p>
                        <div className="col-sm-9">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name='type'
                              id="typeOne"
                              value="one"
                              onChange={handleChange}
                              checked={values.type === 'one'}

                            />
                            <label for="typeOne" className="form-check-label">
                              Única - O cliente pode selecionar apenas um item da lista
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name='type'
                              id="typeMany"
                              value="many"
                              onChange={handleChange}
                              checked={values.type === 'many'}
                            />
                            <label for="typeMany" className="form-check-label">
                              Múltiplas - O cliente pode selecionar mais de um item da lista
                            </label>
                          </div>
                        </div>
                      </div>
                      {
                        values.type === 'many' && (
                          <div className="form-group mb-3">
                            <strong className="text-label">Quantidade</strong>
                            <p>Indique quantos itens podem ser selecionados</p>

                            <div className="row">
                              <div className="col-lg-2">
                                <div className="form-group">
                                  <label for="min" className="text-label">Mínimo</label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    id="min"
                                    min={0}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.min}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-2'>
                                <div className="form-group">
                                  <label for="max" className="text-label">Máximo</label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    id="max"
                                    min={1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.max}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }


                      <div className='col-lg-12'>
                        <p>
                          <strong className="text-label mb-3">Lista de complementos *</strong>
                        </p>
                        <div className='list-complements'>
                          {
                            values.items.map((item, index) => {
                              const fileInputRef = React.createRef();

                              return (
                                <div className='row' key={item.id}>
                                  <div className='col-lg-3'>
                                    <div className='cover-upload mb-3' onClick={() => fileInputRef.current.click()} // Adicione o onClick aqui
                                    >
                                      <input
                                        ref={fileInputRef} // Associe o ref aqui
                                        type="file"
                                        className="form-control hide"
                                        name="cover"
                                        accept="image/*"
                                        onChange={(event) => handleFileChange(event, `items[${index}].cover`, setFieldValue)} />
                                      {
                                        !item.cover ? (
                                          <div className='cover-preview'>
                                            Selecione uma imagem
                                          </div>
                                        ) : (
                                          <img src={item.cover} className='cover-preview' />
                                        )
                                      }
                                    </div>

                                    <div className="form-group mb-3">
                                      <label className="text-label">Código PDV</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].pdvCode`}
                                        placeholder='0000'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={item.pdvCode} />
                                    </div>

                                    <div className="form-group mb-3">
                                      <label className="text-label">Status de venda</label>

                                      <select
                                        type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name={`items[${index}].status`}
                                        value={item.status}
                                      >
                                        <option value={0}>Pausado</option>
                                        <option value={1}>Ativado</option>
                                      </select>
                                    </div>

                                  </div>
                                  <div className='col-lg-6'>
                                    <div className="form-group mb-2">
                                      <label className="text-label">Nome do complemento *</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Ex: Queijo, Bacon, etc.'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name={`items[${index}].title`}
                                        value={item.title}
                                      />
                                      {errors.items && errors.items[index] && errors.items[index].title && <div className="invalid-feedback animated fadeInUp block">{errors.items[index].title}</div>}
                                    </div>

                                    <div className="form-group mb-2">
                                      <label className="text-label">Descrição</label>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder='Descrição do complemento..'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name={`items[${index}].description`}
                                        value={item.description}
                                        style={{ minHeight: 100 }}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label className="text-label">Preço</label>
                                      <Field name={`items[${index}].price`}>
                                        {({ field }) => (
                                          <NumberFormat
                                            {...field}
                                            decimalSeparator=','
                                            className="form-control"
                                            thousandSeparator='.'
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            onValueChange={(values) => {
                                              const { price } = values;
                                              handleChange({ target: { name: `items[${index}].price`, value: price } });
                                            }} />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <hr className='mb-3 mt-3' />
                                </div>
                              )
                            })
                          }

                        </div>


                        <FieldArray name="items">
                          {({ push }) => (
                            <div>
                              <button
                                className='btn btn-outline-primary'
                                type='button'
                                onClick={() =>
                                  push({
                                    id: v4(), // You can generate an appropriate ID here.
                                    type: "additionals"
                                  })
                                }
                              >
                                Adicionar complemento
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </Fragment>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={closeModal}
                  variant="danger light"
                  type='button'
                >
                  Fechar
                </Button>
                <Button type='submit' variant="primary">
                  {!id ? 'Criar grupo de complementos' : 'Salvar grupo de complementos'}
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        );
      }}
    </Formik>
  )
}
