import moment from "moment";
import { parseToBrl } from "../../../utils";
import { ORDER_STATUS } from "../../../utils/settings";
export const COLUMNS = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Código",
    accessor: "code",
  },
  {
    Header: "Status",
    accessor: (item) => ORDER_STATUS[item.status],
  },
  {
    Header: "Preço",
    accessor: (item) => parseToBrl(item.value),
  },
  {
    Header: "Nome do cliente",
    accessor: (item) => item.user.name,
  },
  {
    Header: "Data do pedido",
    accessor: (item) => moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    Header: "Ações",
    accessor: "action",
  },
];
