import React, { useEffect, useState } from "react";
import swal from "sweetalert";

import "./filtering.css";
import { useList } from "./hooks/useList";
import ModalNew from "./modals/ModalNew";
import axiosInstance from "../../services/AxiosInstance";
import { ModalProvider } from "./modals/ModalItem/ModalNewComplementContext";
import Swal from "sweetalert2";
import PedidosTable from "./table";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "react-bootstrap";

export const Products = () => {
  const [show, setShow] = useState(false);
  const [itemDetailId, setItemDetailId] = useState();

  const { list, pagination, setPage, handleGetList } = useList();

  const handleToggleModal = () =>
    setShow(!show);

  const handleToggleModalItem = (id) => {
    setItemDetailId(id);
    handleToggleModal();
  };

  const [params] = useSearchParams();

  useEffect(() => {
    const order = params.get('order');
    if(order) {
      handleToggleModalItem(order);
    }
  }, [params]);

  const handleDelete = async (id) => {
    const response = await swal({
      icon: "warning",
      title: "Tem certeza?",
      text: "Após a deleção, os restaurantes e produtos desse shopping serão perdidos.",
      buttons: [
        {
          text: "Não, cliquei errado",
          value: false,
          closeModal: true,
          className: "btn btn-success",
          visible: true,
        },
        {
          text: "Sim, quero deletar",
          value: "deletar",
          className: "btn btn-danger",
          closeModal: true,
          visible: true,
        },
      ],
    });

    if (response) {
      try {
        Swal.fire({
          title: "Deletando...",
          allowEnterKey: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await axiosInstance.delete(`/hangars/${id}`);
        swal({
          icon: "success",
          title: "Sucesso!",
          description: "Shopping deletado com sucesso!",
          timer: "5000",
        });
      } catch (error) {
        swal({
          icon: "error",
          title: "Oops!",
          description:
            "Houve um erro ao deletar, contate o suporte para mais informações!",
          timer: "10000",
        });
      } finally {
        handleGetList();
      }
    }
  };

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <ModalProvider>
      <ModalNew
        show={show}
        closeModal={() => handleToggleModalItem(null)}
        successCallback={() => {
          handleGetList();
        }}
        id={itemDetailId}
      />

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Pedidos</h3>
      </div>
      <div>
        <PedidosTable
          list={list}
          handleToggleModalItem={handleToggleModalItem}
          handleDelete={handleDelete}
        />
        <Pagination {...pagination} setPage={setPage} />

      </div>
    </ModalProvider>
  );
};
export default Products;
