import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ThemeContext from "./context/ThemeContext";
import { StoresProvider } from './context/StoresProvider';
import WebSocketProvider from './context/WebSocketProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WebSocketProvider>
      <StoresProvider>
        <Provider store={store}>
          <BrowserRouter>
            <ThemeContext>
              <App />
            </ThemeContext>
          </BrowserRouter>
        </Provider>
      </StoresProvider>
    </WebSocketProvider>
  </React.StrictMode>
);
