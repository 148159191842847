//import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import {
  ACCESS_TOKEN_KEY,
  NAMESPACE_USER_DETAIL,
} from "../../enviroment/development";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import axiosInstance from "../../services/AxiosInstance";
import { parseResponse } from "../../utils";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, navigate) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate("/dashboard");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  navigate("/login");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then(async (response) => {
        const { jwt, user, isEmployeeOf } = response.data;
        if(user?.role === 3) {
          return Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Usuário não tem autorização para acessar este sistema!",
          });
        }
        let isEmployeeOfData = null;

        if (isEmployeeOf) {
          isEmployeeOfData = isEmployeeOf.data.map((item) => ({
            role: item.attributes.role,
            restaurant: item.attributes.restaurant.data.id,
            restaurantName: item.attributes.restaurant.data.attributes.title,
          }));
        }

        const toSaveData = parseResponse({
          jwt,
          ...user,
          expiresIn: 3600,
          ...(isEmployeeOf ? { employeeOf: isEmployeeOfData } : {}),
        });

        saveTokenInLocalStorage(toSaveData);
        runLogoutTimer(dispatch, 3600 * 1000, navigate);

        saveTokenInLocalStorage({
          ...toSaveData,
        });

        dispatch(
          loginConfirmedAction({
            ...toSaveData,
          })
        );

        if (toSaveData.employeeOf && toSaveData.employeeOf.length > 0) {
          navigate("/meu-restaurante");
        } else {
          navigate("/shoppings");
        }
        Swal.close();
      })
      .catch((error) => {
        //console.log('error');
        //console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
        Swal.close();
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
