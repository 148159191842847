import axiosInstance from "../../../../services/AxiosInstance"
import Swal from "sweetalert2"
import { ORDER_STATUS_NAMES } from "../../../../utils/settings"

const feedbacks = {
  [ORDER_STATUS_NAMES.rejected]: {
    preSend: 'Rejeitando o pedido...',
    title: 'Pedido rejeitado',
    text: 'Pedido rejeitado com sucesso!',
    icon: 'error'
  },
  [ORDER_STATUS_NAMES.preparing]: {
    preSend: 'Aceitando o pedido...',
    title: 'Pedido aceito',
    text: 'Pedido aceito com sucesso!',
    icon: 'success'
  },
}
export const useNewOrder = () => {
  const handleUpdateStatus = async (code, status, callback) => {
    try {
      Swal.fire({
        title: feedbacks[status].preSend,
        allowEnterKey: false,
        allowEscapeKey: false,
        showConfirmButton: false,

        didOpen: () => {
          Swal.showLoading()
        }
      })
      await axiosInstance.post('/pagarme/orders/status', {
        data: {
          code: code,
          status: status
        }
      })

      Swal.fire({
        title: feedbacks[status].title,
        text: feedbacks[status].text,
        icon: feedbacks[status].icon,
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })
      callback && callback()
    } catch (error) {
      Swal.fire({
        title: 'Erro ao atualizar o pedido',
        text: 'Erro ao atualizar o pedido, tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })
    }
  }

  return {
    handleUpdateStatus
  }
}
