/// Menu
import React, { Component, useContext, useEffect, useReducer, useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

/// Link
import { Link, NavLink } from "react-router-dom";

import { MenuList, menus } from './Menu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useSelector } from "react-redux";
import { ROLES } from "../../../utils/settings";


const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const { auth } = useSelector(store => store.auth);

  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  useEffect(() => {

  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )


  const handleMenuActive = status => {

    setState({ active: status });
    //console.log(state.active);
    if (state.active === status) {
      //setActive('');
      setState({ active: "" });
    }

  }
  const handleSubmenuActive = (status) => {

    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })

    }
    //status.preventDefault();
  }
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu

  const isUserAdmin = auth?.role === ROLES.ADMIN
  console.log('isUserAdmin', isUserAdmin)
  console.log('auth', auth)

  return (
    <div
      className={`dlabnav  border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {menus[isUserAdmin ? 'admin' : 'user'].map((data, index) => {
            let menuClass = data.classsChange;
            const noCollapse = data.noCollapse;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass}>{data.title}</li>
              )
            } else {
              return (
                <li className={` ${state.active === data.title ? 'mm-active' : ''}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ?
                    <Link to={"#"}
                      className="has-arrow"
                      onClick={() => { handleMenuActive(data.title) }}
                    >
                      <i className={data.iconStyle}></i>
                      <span className="nav-text">{data.title}</span>
                    </Link>
                    :
                    <NavLink to={data.to}
                      onClick={() => { handleMenuActive(data.title) }}
                    >
                      <i className={data.iconStyle}></i>
                      <span className="nav-text">{data.title}</span>
                    </NavLink>
                  }
                  {!noCollapse && <Collapse in={state.active === data.title ? true : false}>
                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                      {data.content && data.content.map((data, index) => {
                        return (
                          <>
                            <li key={index}
                              className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                            >
                              {data.content && data.content.length > 0 ?
                                <NavLink to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                  onClick={() => { handleSubmenuActive(data.title) }}
                                >
                                  {data.title}
                                </NavLink>
                                :
                                <Link to={data.to}
                                >
                                  {data.title}
                                </Link>
                              }
                              <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                  {data.content && data.content.map((data, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                        </li>
                                      </>
                                    )
                                  })}
                                </ul>
                              </Collapse>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  </Collapse>}
                </li>
              )
            }
          })}

        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
