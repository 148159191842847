import * as Yup from "yup";

export const productSchema = Yup.object().shape({
  title: Yup.string().required("Oops, parece que você esqueceu do título..."),
  description: Yup.string().required("Oops, parece que você esqueceu da descrição..."),
  serves: Yup.string(),
  productCategory: Yup.string(),
  status: Yup.boolean(),
  price: Yup.string().required("Oops, parece que você esqueceu do preço...")
});
