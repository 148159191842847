export const PAGE_INFO = {
  products: {
    LIST: {
      TITLE: 'Cardápios',
      BUTTON: 'Adicionar categoria',
      FORM: '/produtos/novo',
      EDIT: (id) => `/produtos/${id}`,
    },
    FORM: {
      TITLE: 'Novo produto',
      BUTTON: 'Cadastrar produto',
      REDIRECT: '/produtos'
    },
    EDIT: {
      TITLE: 'Editar produto',
      BUTTON: 'Atualizar produto',
      REDIRECT: '/produtos'
    },
  },
  additionals: {
    LIST: {
      TITLE: 'Produtos adicionais',
      BUTTON: 'Adicionar novo produto adicional',
      FORM: '/produtos-adicionais/novo',
      EDIT: (id) => `/produtos-adicionais/${id}`,
    },
    FORM: {
      TITLE: 'Novo produto adicional',
      BUTTON: 'Cadastrar produto adicional',
      REDIRECT: '/produtos-adicionais'
    },
    EDIT: {
      TITLE: 'Editar produto adicional',
      BUTTON: 'Atualizar produto adicional',
      REDIRECT: '/produtos-adicionais'
    }
  }
}
