import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import axiosInstance from "../../../../services/AxiosInstance";
import { states } from "../../../../utils/states";
import { parseResponse } from "../../../../utils";

const schema = Yup.object().shape({
  title: Yup.string().required(
    "Oops, parece que você esqueceu de inserir o nome do restaurante..."
  ),
  description: Yup.string().optional(),
  street: Yup.string().required(
    "Oops, parece que você esqueceu de inserir o nome da rua..."
  ),
  number: Yup.string().required(
    "Oops, parece que você esqueceu de inserir o número..."
  ),
  neighborhood: Yup.string().required(
    "Oops, parece que você esqueceu de inserir o bairro..."
  ),
  city: Yup.string().required(
    "Oops, parece que você esqueceu de inserir a cidade..."
  ),
  state: Yup.string().required(
    "Oops, parece que você esqueceu de inserir o estado..."
  ),
  cep: Yup.string().required(
    "Oops, parece que você esqueceu de inserir o CEP..."
  ),
});

const DEFAULT_VALUES = {
  title: "",
  description: "",
  street: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  cep: "",
};

function ModalNewRestaurante({ id, show, closeModal, successCallback }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const [listOfHangars, setListOfHangars] = useState([]);
  useEffect(() => {
    setInitialValues(DEFAULT_VALUES);
    if (id) {
      Swal.fire({
        title: "Carregando...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      axiosInstance.get(`/restaurants/${id}`).then(({ data }) => {
        const parsedResponse = parseResponse(data.restaurant);

        setInitialValues({
          ...parsedResponse,
          ...parsedResponse.address,
          hangar: parsedResponse.hangar.id,
        });

        Swal.close();
      });
    }
  }, [id]);

  const handleGetListOfHangars = useCallback(() => {
    axiosInstance.get("/hangars").then(({ data }) => {
      const parsedResponse = parseResponse(data.hangars);
      setListOfHangars(parsedResponse);
    });
  }, []);

  useEffect(() => {
    handleGetListOfHangars();
  }, [handleGetListOfHangars]);

  const onSubmitForm = useCallback(
    (values, props) => {
      Swal.fire({
        title: `${!id ? "Criando" : "Atualizando"} o restaurante...`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const method = id ? "put" : "post";

      axiosInstance[method]("/restaurants", {
        ...values,
        id,
        active: true,
      })
        .then(async () => {
          props.setSubmitting(false);
          await Swal.fire(
            `Restaurante ${id ? "atualizado" : "criado"} com sucesso!`,
            "",
            "success"
          );
          successCallback();
        })
        .catch((error) => {
          props.setSubmitting(false);
          Swal.fire(
            "Oops, houve um erro ao " + id
              ? "atualizar"
              : "criar" + " o restaurante!",
            "Tente novamente, caso o problema persista, entre em contato com a equipe técnica.",
            "error"
          );
        })
        .finally(() => {
          props.resetForm();
          closeModal();
        });
    },
    [id, successCallback, closeModal]
  );

  const handlecep = async (e, setFieldValue) => {
    const cep = e.target.value.replace(/\D/g, "");
    setFieldValue("cep", cep);
    if (cep.length !== 8) {
      return;
    }

    const { data } = await axiosInstance.get(
      `https://viacep.com.br/ws/${cep}/json/`
    );

    if (data.erro) {
      Swal.fire(
        "Oops, CEP inválido!",
        "Por favor, insira um CEP válido.",
        "error"
      );
      return;
    }

    setFieldValue("street", data.logradouro);
    setFieldValue("neighborhood", data.bairro);
    setFieldValue("city", data.localidade);
    setFieldValue("state", data.uf);
  };

  return (
    <Modal className="fade" show={show}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmitForm}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>
                  {id ? "Atualizar" : "Novo"} restaurante
                </Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={closeModal}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <Fragment>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">
                          Nome do restaurante*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                        />
                        {errors.title && touched.title && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.title}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Shopping</label>
                        <select
                          className="form-control"
                          name="hangar"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.hangar}
                        >
                          <option value="">Selecione...</option>
                          {listOfHangars.map((hangar) => (
                            <option key={hangar.id} value={hangar.id}>
                              {hangar.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label className="text-label">CEP*</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => handlecep(e, setFieldValue)}
                          onBlur={handleBlur}
                          value={values.cep}
                        />
                        {errors.cep && touched.cep && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.cep}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Endereço*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="street"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.street}
                        />
                        {errors.street && touched.street && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.street}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group mb-3">
                        <label className="text-label">Número*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.number}
                        />
                        {errors.number && touched.number && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.number}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="form-group mb-3">
                        <label className="text-label">Complemento</label>
                        <input
                          type="text"
                          className="form-control"
                          name="complement"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.complement}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="text-label">Bairro*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="neighborhood"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.neighborhood}
                        />
                        {errors.neighborhood && touched.neighborhood && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.neighborhood}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label className="text-label">Cidade*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                        />
                        {errors.city && touched.city && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.city}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label className="text-label">Estado*</label>
                        <select
                          className="form-control"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                        >
                          <option value="">Selecione...</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.abbreviation}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        {errors.state && touched.state && (
                          <div className="invalid-feedback animated fadeInUp">
                            {errors.state}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={closeModal}
                  variant="danger light"
                  type="button"
                >
                  Fechar
                </Button>
                <Button type="submit" variant="primary">
                  {!id ? "Criar" : "Atualizar"} restaurante
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ModalNewRestaurante;
