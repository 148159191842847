export const PAGE_INFO = {
  LIST: {
    TITLE: 'Shoppings',
    BUTTON: 'Adicionar usuário',
    FORM: '/shoppings/novo',
    EDIT: (id) => `/shoppings/${id}`,
  },
  FORM: {
    TITLE: 'Novo usuário',
    BUTTON: 'Cadastrar usuário',
    REDIRECT: '/shoppings'
  },
  EDIT: {
    TITLE: 'Editar usuário',
    BUTTON: 'Atualizar usuário',
    REDIRECT: '/shoppings'
  },
}
