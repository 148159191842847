export const states = [
  {
    "id": 1,
    "name": "Acre",
    "abbreviation": "AC",
    "country_id": 1
  },
  {
    "id": 2,
    "name": "Alagoas",
    "abbreviation": "AL",
    "country_id": 1
  },
  {
    "id": 3,
    "name": "Amapá",
    "abbreviation": "AP",
    "country_id": 1
  },
  {
    "id": 4,
    "name": "Amazonas",
    "abbreviation": "AM",
    "country_id": 1
  },
  {
    "id": 5,
    "name": "Bahia",
    "abbreviation": "BA",
    "country_id": 1
  },
  {
    "id": 6,
    "name": "Ceará",
    "abbreviation": "CE",
    "country_id": 1
  },
  {
    "id": 7,
    "name": "Distrito Federal",
    "abbreviation": "DF",
    "country_id": 1
  },
  {
    "id": 8,
    "name": "Espírito Santo",
    "abbreviation": "ES",
    "country_id": 1
  },
  {
    "id": 9,
    "name": "Goiás",
    "abbreviation": "GO",
    "country_id": 1
  },
  {
    "id": 10,
    "name": "Maranhão",
    "abbreviation": "MA",
    "country_id": 1
  },
  {
    "id": 11,
    "name": "Mato Grosso",
    "abbreviation": "MT",
    "country_id": 1
  },
  {
    "id": 12,
    "name": "Mato Grosso do Sul",
    "abbreviation": "MS",
    "country_id": 1
  },
  {
    "id": 13,
    "name": "Minas Gerais",
    "abbreviation": "MG",
    "country_id": 1
  },
  {
    "id": 14,
    "name": "Pará",
    "abbreviation": "PA",
    "country_id": 1
  },
  {
    "id": 15,
    "name": "Paraíba",
    "abbreviation": "PB",
    "country_id": 1
  },
  {
    "id": 16,
    "name": "Paraná",
    "abbreviation": "PR",
    "country_id": 1
  },
  {
    "id": 17,
    "name": "Pernambuco",
    "abbreviation": "PE",
    "country_id": 1
  },
  {
    "id": 18,
    "name": "Piauí",
    "abbreviation": "PI",
    "country_id": 1
  },
  {
    "id": 19,
    "name": "Rio de Janeiro",
    "abbreviation": "RJ",
    "country_id": 1
  },
  {
    "id": 20,
    "name": "Rio Grande do Norte",
    "abbreviation": "RN",
    "country_id": 1
  },
  {
    "id": 21,
    "name": "Rio Grande do Sul",
    "abbreviation": "RS",
    "country_id": 1
  },
  {
    "id": 22,
    "name": "Rondônia",
    "abbreviation": "RO",
    "country_id": 1
  },
  {
    "id": 23,
    "name": "Roraima",
    "abbreviation": "RR",
    "country_id": 1
  },
  {
    "id": 24,
    "name": "Santa Catarina",
    "abbreviation": "SC",
    "country_id": 1
  },
  {
    "id": 25,
    "name": "São Paulo",
    "abbreviation": "SP",
    "country_id": 1
  },
  {
    "id": 26,
    "name": "Sergipe",
    "abbreviation": "SE",
    "country_id": 1
  },
  {
    "id": 27,
    "name": "Tocantins",
    "abbreviation": "TO",
    "country_id": 1
  }
];

export const occupations = [
  {
    "id": 1,
    "name": "Gerente"
  },
  {
    "id": 2,
    "name": "Atendente"
  }
];

export const getOccupationText = (id) => {
  return occupations.find(occupation => String(occupation.id) === String(id)).name;
}
